import { Fragment, useState } from "react";
import styles from "../ClientDetails.module.scss";
import CategoriaComponent from "../SinContactar/Categoria";

const SinPagoComponent = ({
  details,
  hide,
  registrarPago,
  agregarNota,
  aplazarContacto,
  cancelar,
  admin,
  retirar,
}) => {
  const [monto, setMonto] = useState("");
  const [concepto, setConcepto] = useState("");
  const [marca, setMarca] = useState("Bien Crediticio");
  const [cuenta, setCuenta] = useState("Nueva Apoyando BBVA 5434");
  const [razon, setRazon] = useState("Apoyando tu futuro SAPI de CV");
  const [nota, setNota] = useState("");

  const [loading, setLoading] = useState(false);

  const resetForm = () => {
    setMonto(0);
    setConcepto("");
    setMarca("Bien Crediticio");
    setCuenta("BBVA Respaldo");
    setRazon("Apoyando tu futuro SAPI de CV");
    setNota("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);

    registrarPago(details._id, 0, {
      monto: monto.replace(/\./g, ""),
      concepto,
      marca,
      cuenta,
      razon,
    }).then((res) => setLoading(false));
  };

  const handleComSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);

    agregarNota(details._id, nota).then((res) => setLoading(false));
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);
    setLoading(aplazarContacto(details._id, nota));
  };

  const handleEsperaSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);
    setLoading(await cancelar(details._id, 60, nota));
  };

  const handleCancelaSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (nota === "") {
      return;
    }

    setLoading(true);
    setLoading(await cancelar(details._id, 104, nota));
  };

  return (
    <div>
      <div className={styles.cabecera}>
        <h1>Esperando pago</h1>
        <button
          onClick={() => {
            resetForm();
            hide();
          }}
          className={styles.closeBttn}
        >
          <span className="material-symbols-outlined">close</span>
        </button>
      </div>

      <div className={styles.body}>
        <div className={styles.tarjetaDatos}>
          <h1 style={{ textAlign: "center" }}>
            <b>{details.fecha_actualizacion}</b>
          </h1>
          <br />
          <h1 style={{ textAlign: "center" }}>{details.nombre}</h1>
        </div>

        <CategoriaComponent tipo="datos" data={details} />

        {admin ? (
          <button
            className={styles.badButton}
            onClick={() => {
              setLoading(retirar(details._id));
            }}
          >
            Retirar para reciclaje
          </button>
        ) : (
          <></>
        )}

        <span style={{ border: ".5px solid #02394a" }} />
        <p>Asignaciones: {details.pago.length}</p>

        {details.pago.map((item, id) => (
          <CategoriaComponent
            key={`categoria_pago_${id}`}
            tipo="asignacion"
            fecha={item.fecha_asignado}
            data={item}
          />
        ))}

        {!details.solicitud ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Solicitudes: {details.solicitud.length}</p>

            {details.solicitud.map((item, id) => (
              <CategoriaComponent
                key={`categoria_solicitud_${id}`}
                tipo="solicitud_nueva"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.viabilidad ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Viabilidad: {details.solicitud.length}</p>
            {details.viabilidad.map((item, id) => (
              <CategoriaComponent
                key={`categoria_viabilidad_${id}`}
                tipo={
                  item.tipo === "Persona fisica"
                    ? "viabilidadFisica"
                    : "viabilidadMoral"
                }
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.cotizaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Cotizaciones: {details.cotizaciones.length}</p>
            {details.cotizaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_cotiza_${id}`}
                tipo="cotizacion"
                fecha={item.fecha_cotizacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {!details.aprobaciones ? (
          <></>
        ) : (
          <Fragment>
            <span style={{ border: ".5px solid #02394a" }} />
            <p>Aprobaciones: {details.aprobaciones.length}</p>
            {details.aprobaciones.map((item, id) => (
              <CategoriaComponent
                key={`categoria_aprobacion_${id}`}
                tipo="aprobacion"
                fecha={item.fecha_aprobacion}
                data={item}
              />
            ))}
          </Fragment>
        )}

        <span style={{ border: ".5px solid #02394a" }} />

        {!details.notas ? (
          <></>
        ) : (
          <Fragment>
            {details.notas.map((item, id) => (
              <CategoriaComponent
                key={`categoria_nota_${id}`}
                tipo="nota"
                fecha={item.fecha}
                data={item}
              />
            ))}
          </Fragment>
        )}

        {/* Agrega una nota */}
        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Agregar una nota</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => {
                  setNota(e.target.value);
                }}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    onClick={handleComSubmit}
                    className={styles.passiveButton}
                  >
                    Guardar
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>

        <form onSubmit={handleSubmit} id="auth_form">
          <div className={styles.cotizacion_form}>
            <div className={styles.cotizacion_cabecera}>
              <h1>Detalles del pago</h1>
            </div>

            <div className={styles.cotizacion_cuerpo}>
              <div className={styles.cotizacion_field}>
                <label>Monto</label>
                <div className={styles.cotizacion_input_inicio}>
                  <input
                    required
                    value={monto}
                    onChange={(e) => {
                      setMonto(e.target.value);
                    }}
                  />
                  <span>$</span>
                </div>
              </div>

              <div className={styles.cotizacion_field}>
                <label>Concepto</label>
                <div className={styles.cotizacion_input_inicio}>
                  <input
                    required
                    value={concepto}
                    onChange={(e) => {
                      setConcepto(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className={styles.cotizacion_field}>
                <label>Marca</label>
                <select
                  value={marca}
                  onChange={(e) => setMarca(e.target.value)}
                >
                  <option value="Bien Crediticio">Bien Crediticio</option>
                  <option value="Fortuna Capital">Fortuna Capital</option>
                  <option value="Solucion Financiera">
                    Solucion Financiera
                  </option>
                  <option value="Prestamo 24">Prestamo 24</option>
                  <option value="Prestamo 24-7">Prestamo 24-7</option>
                </select>
              </div>

              <div className={styles.cotizacion_field}>
                <label>Cuenta</label>
                <select
                  value={cuenta}
                  onChange={(e) => setCuenta(e.target.value)}
                >
               {/*   <option>Nueva Apoyando BBVA 5434</option>Error con depositos de azteca - ATF2 */}
                  <option>Auxiliar Santander 7714</option>{/*Funcional*/ } 
                  <option>Juan BBVA-OXXO 9385</option>{/*Unam oro*/ } 
                  <option>Juan BANREGIO-OXXO 2968 </option>{/*Hey Banco*/ }  
<option>Juan BANREGIO-TRANSFERENCIAS 1771 </option>{/*Hey Banco*/ } 
<option>Tina BANREGIO-OXXO xxxx </option>{/*Hey Banco*/ } 
<option>Tina BANREGIO-TRANSFERENCIAS 1685 </option>{/*Hey Banco*/ } 
                  <option>Auxiliar BBVA 8245</option>{/*La que se tuvo que cancelar - Auxiliar*/ } 
                  <option>BEP BBVA 4028</option>
                  <option>BEP HSBC 2584</option>
                  <option>Apoyando BBVA 8935</option>{/*ATF 1*/ } 
                </select>
              </div>

              <div className={styles.cotizacion_field}>
                <label>Razón Social</label>
                <select
                  value={razon}
                  onChange={(e) => setRazon(e.target.value)}
                >
                  <option>Apoyando tu futuro SAPI de CV</option>
                  <option>Bienestar para tu futuro SAPI de CV</option>

                  <option>Respaldo y proyeccion empresarial SAPI de CV</option>
                </select>
              </div>
            </div>

            {loading ? (
              <>Cargando ...</>
            ) : (
              <button
                form="auth_form"
                type="submit"
                className={styles.okButton}
              >
                Registrar pago
              </button>
            )}
          </div>
        </form>

        <form>
          <div className={styles.comentario_form}>
            <div className={styles.comentario_cabecera}>
              <h1>Da más detalles</h1>
            </div>
            <div className={styles.cotizacion_cuerpo}>
              <textarea
                required
                value={nota}
                onChange={(e) => setNota(e.target.value)}
              ></textarea>

              {loading ? (
                <>Cargando ...</>
              ) : (
                <Fragment>
                  <button
                    type="submit"
                    onClick={handleCommentSubmit}
                    className={styles.passiveButton}
                  >
                    No contesta
                  </button>

                  <button
                    type="submit"
                    className={styles.okButton}
                    onClick={handleEsperaSubmit}
                  >
                    Poner en espera
                  </button>

                  <button
                    type="submit"
                    className={styles.badButton}
                    onClick={handleCancelaSubmit}
                  >
                    No va a pagar
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SinPagoComponent;
